// put your main mutations here
// export default {
// 	isLoading(state, flag) {
// 		state.isLoading = flag
// 	},
// 	isAuthenticated(state, flag) {
// 		state.isAuth = flag
// 	},
	// setshowAddMembershipPlan(state, value) {
 //  		this.state.setshowAddMembershipPlan = value
 //  	},
// }


import localforage from 'localforage'
import { isEmpty } from 'lodash'

// const IS_LOADING = (state, flag) => {
// 	state.isLoading = flag
// }

const UNSET_ALERT = (state, alertName) => {
	state.alerts[alertName] = false
}

const SET_ALERT = (state, alertName) => {
	// Reset all alerts
	Object.entries(state.alerts).forEach((key, value) => {
		state.alerts[key] = false
	})
	state.alerts[alertName] = true

	// Fadeout the alert in configured time
	setTimeout(function() {
		state.alerts[alertName] = false
    }, window.alertDuration);
}

const SET_ALERT_MESSAGE = (state, message) => {
	state.alerts.message = message
}

const HANDLE_MAIN_HEADER_BASED_ON_AUTH = (state) => {
	localforage.getItem('authtoken').then((response) => {
		if (isEmpty(response)) {
			// state.headers.mainHeaderLinks[8].show = false
			// state.headers.mainHeaderLinks[9].show = true
			// state.headers.mainHeaderLinks[10].show = false
			// state.headers.mainHeaderLinks[11].show = false

		} else {
			// state.headers.mainHeaderLinks[8].show = true
			// state.headers.mainHeaderLinks[9].show = false
			// state.headers.mainHeaderLinks[10].show = true
			// state.headers.mainHeaderLinks[11].show = true

		}
	})

}
const SET_CITY_DATA = (state, data) => {
    state.cities = data
}

const SET_COUNTRY_DATA = (state, data) => {
    state.countries = data
}
const setErrorLogList = (state, data) => {
    state.errorLogList.data = data.data.data;
    state.errorLogList.links = data.data.links;
    state.errorLogList.meta = {
		current_page: data.data.current_page,
		from: data.data.from,
		to:  data.data.to,
		total: data.data.total,
		perPage: data.data.per_page
	}
}
export default {
	// IS_LOADING,
	UNSET_ALERT,
	SET_ALERT,
	SET_ALERT_MESSAGE,
	HANDLE_MAIN_HEADER_BASED_ON_AUTH,
	SET_CITY_DATA,
	SET_COUNTRY_DATA,
	setErrorLogList,
	changeLanguageSlug(state, locale) {
		state.locale = locale
		localforage.setItem('locale', locale);
		window.location = window.location.href
 	},
	isAuthenticated(state, flag) {
		state.isAuth = flag
	},
	isAuthenticated(state, flag) {
		state.isAuth = flag
	},
}


