export const getShowEditViewLocalization = (state) => {
    return state.showEditViewLocalization;
}

export const getActionCategory = (state) => {
    return state.editTranslationModal.action;
}

export const getImportedLanguageCodeListData = (state) => {
	return state.importedLanguageCodeList
}
export const getMasterLanguageListData = (state) => {
	return state.masterLanguageList
}
export const getLanguageNameBySlug = (state) => {
	return state.languageNameBySlug
}
export const getLanguageList = (state) => {
    return state.languageList.data
}

export const getLanguageListLinks = (state) => {
    return state.languageList.links
}

export const getLanguageListMeta = (state) => {
    return state.languageList.meta
}