export default {
    methods: {
      assetUrl(url) {
        // return process.env.BASE_URL + '/' + url.replace(/^\/|\/$/g, '')
        return process.env.BASE_URL + url.replace(/^\/|\/$/g, '')
      },
      gIndicator(status){
          if(status == 'start'){
              document.getElementById("template_loader_indicator").style.display="block";
          }
          if(status == 'stop'){
              document.getElementById("template_loader_indicator").style.display="none";
          }
      },
      haspermission(permission){

        let hasPermission = false;
        const permissions = ['sr-my-profile'];
        const getData = JSON.parse(localStorage.getItem('data'));
        const getRoles = getData && getData.hasOwnProperty('roles') ? getData.roles : false;
        
        if(getRoles && getRoles.length){
            for(var i=0; i<getRoles.length; i++){
                const getPermission = getRoles[i].hasOwnProperty('permissions') ? getRoles[i].permissions : false;

                if(getPermission && getPermission.length){
                    for(var k=0; k<getPermission.length; k++){
                        if(!permissions.includes(getPermission[k].name)){
                            permissions.push(getPermission[k].name);
                        }
                    }
                }
            }
        }
        if (permissions.length) {
            hasPermission = permissions.includes(permission) ? true : false;
        }
        return hasPermission;
      },
      gFormatDateYMD(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
     },
     gFormatedAmtNumWithoutDecimal(num) {
        let newNumber = Math.round(num);

        return newNumber;
    },
    gFormatedAmount(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    
    gFormatedNumber(num) {
        return parseFloat(num).toFixed(2);
    },
    gFormatedAmountNumber(num) {
        let amt = this.gFormatedAmount(this.gFormatedNumber(num));
        return amt;
    },
    async bellNotification(frmData){
        try{
            const result = await this.$store.dispatch("sendBellNotification",{payload: frmData});
        }catch(e){
            console.log('error in bell notification',e);
        }
    },
    }
  }