const getMainHeaderLinks = (state) => {
	return state.headers.mainHeaderLinks
}

const getWebLang = (state) => {
	return state.locale;
}

const getCityData = (state) => {
	return state.cities
}
const getCountryData = (state) => {
	return state.countries
}

const getRESTApiURL = (state) => {
	return process.env.VUE_APP_LOCAL_API;
}

const getRESTApiURL_Client = (state) => {
	return process.env.VUE_APP_LOCAL_API;
}
const getRESTApiURL_backend = (state) => {
	return process.env.VUE_APP_SMART_RECRUIT_API_URL;
}
const getErrorLogList = (state) => {
    return state.errorLogList.data;
}

const getErrorLogListLinks = (state) => {
    return state.errorLogList.links;
}

const getErrorLogListMeta = (state) => {
    return state.errorLogList.meta;
}
export default {
    // getMainHeaderLinks,
    getCityData,
  	getCountryData,
  	getWebLang,
	getRESTApiURL,
	getRESTApiURL_Client,
	getRESTApiURL_backend,
	getErrorLogList,
	getErrorLogListLinks,
	getErrorLogListMeta,
    getIsAuth() {
		return state.isAuth
	},
}


