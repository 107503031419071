import localforage from 'localforage'

const getCitiesFromBackOffice = ({ commit}, { payload, context}) => {
  var string = payload ? payload : 'a';
  return window.axios({
      method: 'GET',
      url: process.env.VUE_APP_SSO_API_URL + `api/get-city/` + string,
  }).then((response) => {
      return response.data;
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
      context.errors = error.response.data
      return false
  })
};

const getCountryState = ({ commit}, { payload, context}) => {
  return window.axios({
      method: 'GET',
      url: process.env.VUE_APP_SSO_API_URL + `api/get-country-state-by-city/` + payload.id,
  }).then((response) => {
      return response.data;
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
      context.errors = error.response.data
      return false
  })
};

const countryData = ({ commit, context}, query = 'some') => {
  
  return window.axios.get(process.env.VUE_APP_SSO_API_URL  + "api/countries/" + query).then((response) => {
      commit("SET_COUNTRY_DATA", response.data);
      return true;
    }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong City", { root: true });
      context.errors = error.response.data.errors;
      return false;
    });   
};

const getCountryAndCountryCode = ({ commit}, {context}) => {
  return window.axios({
      method: 'GET',
      url: process.env.VUE_APP_SSO_API_URL + `api/phone-code`,
  }).then((response) => { 
      return response.data;
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
      context.errors = error.response.data
      return false
  })
};

const fetchCommonData = ({ commit}, {payload, context})=>{
  return window.axios({
      method: 'GET',
      url: process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/get-common-data/'+payload.type+'?search=' +payload.search,
  }).then((res) => {
      return res.data; 
  }).catch(e => {
    commit('SET_ALERT', 'warning', { root: true })
    commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
    context.errors = error.response.data
    return false
  });
};

const languagesList = ({ commit, context}) => {
  
  return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL  + "api/languages-list").then((response) => {
      if(response.data.success){
        return response.data.data;
      }else{
        return false;
      }
    }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong City", { root: true });
      return false;
    });   
};
const industryList = ({commit, dispatch}) =>{
  return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/master-industry-list').then((response) => {
      return response.data;
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
  })
}
const getIndustrySubTypeList = ({commit, dispatch},{payload}) =>{
  return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/master-industry-sub-list/'+payload.id).then((response) => {
      return response.data;
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
  })
}
const sendBellNotification = ({ commit, dispatch }, { payload, context }) => {
  try {
      return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/bell/notify/records',payload).then((response) => {
          return response.data;
      })
  } catch (error) {
      console.log("Error in sendBellNotification response action", error);
  }
}
const getBellNotification = ({ commit, dispatch }) => {
  try {
    let token = localStorage.getItem('localforage/smartrecruitadmin/authtoken') ? localStorage.getItem('localforage/smartrecruitadmin/authtoken').replace('"', '').replace('"', '') : '';
      return window.axios({
          method: 'GET',
          url: process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/bell/notify/records',
          headers: {
            'Authorization': 'Bearer ' + token
          },
      }).then((res) => {
          return res.data; 
      })
  } catch (error) {
      console.log("Error in getBellNotification response action", error);
  }
}
const markSeenBellNotification = ({ commit, dispatch }, { payload, context }) => {
  try {
      let token = localStorage.getItem('localforage/smartrecruitadmin/authtoken') ? localStorage.getItem('localforage/smartrecruitadmin/authtoken').replace('"', '').replace('"', '') : '';
      return window.axios({
          method: 'POST',
          url: process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/bell/notify/mark-seen',
          headers: {
            'Authorization': 'Bearer ' + token
          },
          data:payload
      }).then((res) => {
          return res.data; 
      })
  } catch (error) {
      console.log("Error in markSeenBellNotification response action", error);
  }
}

const getErrorLogList = ({ commit, dispatch },query='') => {
  return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/get-error-logs'+query).then((response) => {
      commit('setErrorLogList', response.data);
      return response.data;
  }).catch((error) => {
      dispatch("auth/errorHandler",error.response.status,{ root:true });
      return false
  })
}
const updateErrorLogStatus = ({ commit, dispatch },payload) => {
  return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/update-error-log',payload.payload).then((response) => {
      return response.data;
  }).catch((error) => {
      dispatch("auth/errorHandler",error.response.status,{ root:true });
      return false
  })
}
const deleteErrorLog = ({ commit, dispatch },payload) => {
  return window.axios.delete(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/delete-error-log/'+payload.payload.id).then((response) => {
      return response.data;
  }).catch((error) => {
      dispatch("auth/errorHandler",error.response.status,{ root:true });
      return false
  })
}
export default {
	  countryData,
    getCountryAndCountryCode,
    getCitiesFromBackOffice,
    getCountryState,
    fetchCommonData,
    languagesList,
    industryList,
    getIndustrySubTypeList,
    sendBellNotification,
    getBellNotification,
    markSeenBellNotification,
    getErrorLogList,
    updateErrorLogStatus,
    deleteErrorLog
};

