// Put your main states here
export default {
    // isLoading: false,
    fullPage: true,
    // color: 'red',
    // loader: 'dots',
    // opacity: 0.8,
    isAuth: false,
    locale:'',
    countries: [],
    alerts: {
        default: false,
        success: false,
        warning: false,
        info: false,
        message: null
    },

    // headers: {
    //     mainHeaderLinks: [
    //             {name: 'head-s', link:process.env.VUE_APP_CLIENT_URL+'advanced-search/job', class: 'text-gray-700 hover:text-blue-500', show: true},
    //             {name: 'head-e', link:process.env.VUE_APP_CLIENT_URL+'employer', class: 'text-gray-700 hover:text-blue-500', show: true},
    //             {name: 'head-js', link:process.env.VUE_APP_CLIENT_URL+'job-seeker', class: 'text-gray-700 hover:text-blue-500', show: true},
    //             {name: 'TITP', link:process.env.VUE_APP_CLIENT_URL+'titp?filter=job&titp=1', class: 'text-gray-700 hover:text-blue-500', show: true},
    //             {name: 'head-ot', link:process.env.VUE_APP_CLIENT_URL+'outsourcing-tender', class: 'text-gray-700 hover:text-blue-500', show: true},
    //             {name: 'head-jf', link:process.env.VUE_APP_CLIENT_URL+'job-fair', class: 'text-gray-700 hover:text-blue-500', show: true},
    //             {name: 'head-a', link:process.env.VUE_APP_CLIENT_URL+'advertisements', class: 'text-gray-700 hover:text-blue-500', show: true},
    //             {name: 'head-rs', link:process.env.VUE_APP_CLIENT_URL+'relocation-service', class: 'text-gray-700 hover:text-blue-500', show: true},
    //             {name: 'head-d', link:process.env.VUE_APP_CLIENT_URL+'dashboard-home', class: 'text-gray-700 hover:text-blue-500', show: false},
    //             {name: 'su-title', link:process.env.VUE_APP_REG_NOW_URL, class: 'text-red-600 hover:text-red-700 font-semibold', show: false},
    //         ]
    // },
    myprofile: {
        firstname: "",
        lastname: "",
        designation: "",
        img: "/images/Vectorprofile.png",
        role:"",
        email:''
    },
    errorLogList:{
        data: null,
        links: null,
        meta: null
    },
    
}
