export default {
    showEditViewLocalization:false,
    importedLanguageCodeList:[],
    masterLanguageList:[],
    languageNameBySlug:'',
    languageList:{
        data: null,
        links: null,
        meta: null
    },
};
