export const showEditViewLocalization = (state, flag) => {
    state.showEditViewLocalization = flag;
};

export const setActionCategory = (state, action) => {
    state.editTranslationModal.action = action;
};

export const setImportedLanguageCodeListData = (state, data) => {
	state.importedLanguageCodeList = data.data.data
}
export const setMasterLanguageListData = (state, data) => {
	state.masterLanguageList = data.data.data.data
}

export const setLanguageNameBySlug = (state, data) => {
	state.languageNameBySlug = data.data.data
}
export const setLanguageList = (state, data) => {
    state.languageList.data = data.data.data;
    state.languageList.links = data.data.links;
    state.languageList.meta = {
		current_page: data.data.current_page,
		from: data.data.from,
		to:  data.data.to,
		total: data.data.total,
		perPage: data.data.per_page
	}
}